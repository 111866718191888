<template>
  <HxTable v-model:columns="columns" :reload="loadData" :type="2">
    <!-- 页面头部查询模块 -->
    <template #search>
      <a-form layout="inline">
        <a-form-item label="" style="width: 13%">
          <a-date-picker
            v-model:value="searchForm.coopStartTime"
            @change="timeStartChange"
            :show-time="{ format: 'HH:mm' }"
            format="YYYY-MM-DD HH:mm"
            placeholder="合作时间起"
            style="width: 100%"
          />
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-date-picker
            v-model:value="searchForm.coopEndTime"
            @change="timeEndChange"
            :show-time="{ format: 'HH:mm' }"
            format="YYYY-MM-DD HH:mm"
            placeholder="合作时间终"
            style="width: 100%"
          />
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-select
            v-model:value="search.customerType"
            :options="$store.state.enumAll.CustomerTypeEnum"
            @change="customerChange"
            placeholder="客户类型"
            class="ransport_search_input"
            allowClear
          />
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-select
            v-model:value="search.settlementMode"
            :options="$store.state.enumAll.CustomerSettlementEnum"
            @change="settlementChange"
            placeholder="结算方式"
            allowClear
          />
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-input
            v-model:value="searchForm.name"
            placeholder="客户名称"
            class="ransport_search_input"
          />
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-input
            v-model:value="searchForm.saleName"
            placeholder="业务员"
            class="ransport_search_input"
          />
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-input
            v-model:value="searchForm.mobile"
            placeholder="联系电话"
            class="ransport_search_input"
          />
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-date-picker
            v-model:value="searchForm.followStartTime"
            style="width: 100%"
            @change="followStartTimeChange"
            placeholder="跟进时间起"
            allowClear
          />
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-date-picker
            v-model:value="searchForm.followEndTime"
            style="width: 100%"
            @change="followEndTimeChange"
            placeholder="跟进时间终"
            allowClear
          />
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-select
            :options="$store.state.enumAll.IntentionEnum"
            v-model:value="searchForm.intentionTypeId"
            placeholder="意向程度"
            allowClear
          >
          </a-select>
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-select v-model:value="searchForm.orgId" placeholder="部门" allowClear>
            <a-select-option
              v-for="item in $store.state.app.orgBusList"
              :key="item.id"
              :value="item.id"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-input-number
            style="width: 100%"
            v-model:value="searchForm.notOrderMonth"
            :min="1"
            placeholder="是否几个月未下单的"
          />
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-select v-model:value="searchForm.tag" style="width: 100%" placeholder="客户标签" allowClear>
            <a-select-option v-for="item in $store.state.app.labelAll.CUSTOMER_LABEL" :value="item.value"
                             :key="item.value">{{ item.label }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-select v-model:value="searchForm.isBlack" allowClear placeholder="是否拉黑">
            <a-select-option :value="1">是</a-select-option>
            <a-select-option :value="0">否</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-select style="width: 50%" allowClear v-model:value="searchForm.qualityGrade" :options="$store.state.app.labelAll.CUSTOMER_GRADE" placeholder="质量等级">
          </a-select>
          <a-select style="width: 50%" allowClear v-model:value="searchForm.riskGrade" :options="$store.state.app.labelAll.CUSTOMER_GRADE" placeholder="风险等级">
          </a-select>
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-select v-model:value="searchForm.vehicleGrade" allowClear :options="$store.state.app.labelAll.CUSTOMER_VEHICLE_LEVEL" placeholder="台量等级">
          </a-select>
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-select v-model:value="searchForm.coopType" allowClear :options="$store.state.app.labelAll.DEAL" placeholder="成交状态">
          </a-select>
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-select  style="width: 50%" v-model:value="searchForm.coopOldType" allowClear :options="$store.state.app.labelAll.COOP" placeholder="合作状态">
          </a-select>
          <a-select style="width: 50%" v-model:value="searchForm.customerSourceType" allowClear :options="$store.state.enumAll.customerSource" placeholder="客户来源">
          </a-select>
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-select v-model:value="searchForm.intentionLevel" allowClear :options="$store.state.app.labelAll.INTENTION_LEVEL" placeholder="意向等级">
          </a-select>
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-input-number style="width: 50%" v-model:value="searchForm.followStartNum" :min="0" placeholder="跟进次数始" />

          <a-input-number style="width: 50%" v-model:value="searchForm.followEndNum" :min="0" placeholder="跟进次数末" />
        </a-form-item>
        <a-space>
          <a-button
            :loading="searchLoading"
            type="primary"
            @click="onSearch"
            :disabled="searchLoading"
          >
            查询
          </a-button>
          <a-button @click="reset"> 重置 </a-button>
        </a-space>
      </a-form>
    </template>
    <!-- 上方的左边按钮 -->
    <template #toolbarLeft>
      <a-space>
        <a-button
          :disabled="searchReceive"
          @click="customTransferShow = true"
          class="tool-color-344563"
        >
          移交
        </a-button>
      </a-space>
    </template>
    <!-- 表格主体 -->
    <a-table
      size="small"
      :columns="columns"
      :row-key="(record) => record.customerId"
      :data-source="listData"
      bordered
      :pagination="pagination"
      :scroll="{ x: 2200 }"
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      :loading="searchLoading"
      @change="handleTableChange"
    >
      <template #name="{ record }">
        <span><a @click="onSee(record)">{{ record.name }}</a></span>
        <a-tag color="#383838" v-show="record.isBlack?.value !== 0">已拉黑</a-tag>
        <span v-if="record.tagNames">
          <div>
            <a-popover title="标签" placement="bottom">
              <template #content>
                <p style="padding: 0 10px;"> <a-tag color="#009587" v-for="item in record.tagNames.split(',')" :key="item">{{item}}</a-tag></p>
              </template>
              <div style=display:flex>
                <div class="title-label" style="padding:2px;color:#F0F2F5;width:100px;overflow:hidden;white-space:nowrap;text-overflow: ellipsis;border-radius: 5px;background-color: #009587;font-size:12px">
                  {{record.tagNames.replaceAll(',',' ') }}
                </div>
              </div>
            </a-popover>
          </div>
          <!--        <a-tag color="#383838" v-for="item in record.tagNames.split(',')" :key="item">{{item}}</a-tag>-->
        </span>
      </template>
<!--      </template>-->
      <template #customerStatus="{record}">
        <div>
          <a-tag color="#E96B00" v-show="record.coopType">成交-{{ record.coopType?.label }}</a-tag>
          <a-tag color="#E96B00" v-show="record.coopOldType">合作-{{ record.coopOldType?.label }}</a-tag>
        </div>
      </template>
      <template #intentionLevel="{ record }">
        <a-tag color="#FFA301" v-if="record.intentionLevel">{{record.intentionLevel?.label}}</a-tag>
      </template>
      <template #level="{ record }">
        <div>
          <a-tag color="#D43030" v-show="record.riskGrade">风险-{{ record.riskGrade?.label }}</a-tag>
          <a-tag color="#003470" v-show="record.vehicleGrade">台量-{{ record.vehicleGrade?.label }}</a-tag>
          <a-tag color="#AC33C1" v-show="record.qualityGrade">质量-{{ record.qualityGrade?.label }}</a-tag>
          <!-- <a-tag color="#0066CC" v-show="record.customerSourceType">来源-{{ record.customerSourceType?.label }}</a-tag> -->
        </div>
      </template>
      <template #data="{ record }">
        <a-popover title="详细信息">
          <template #content>
            <p style="width: 400px; padding: 0 10px">{{ record.follows }}</p>
          </template>
          <div>
            <div
              style="
                width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              "
            >
              {{ record.follows }}
            </div>
          </div>
        </a-popover>
      </template>
      <template #channelLabel="{ record }">
        <span>{{ record.channelLabel }} {{ record.channelSubLabel }}</span>
      </template>
      <template #contractTime="{ record }">
        <div>
          <div v-show="record.contractStartTime">始：{{ record.contractStartTime }}</div>
          <div v-show="record.contractEndTime">末：{{ record.contractEndTime }}</div>
        </div>
      </template>
      <template #isFocus="{ record }">
        <a @click="focusCustomer(record,2)" v-if="record.isFocus?.value == 1">取消关注</a>
        <a @click="focusCustomer(record,1)" v-else>关注</a>
      </template>
    </a-table>
    <a-drawer
      title="客户信息"
      width="80%"
      placement="right"
      v-model:visible="followShow"
      @close="onClose"
      :destroyOnClose="true"
    >
      <CustomerDetail :customerDetailData="detailDataRecord" :customerId="customerId" type="2" num="6" @updatePage="loadData" />
    </a-drawer>
  </HxTable>
  <!-- 移交 -->
  <a-modal v-model:visible="customTransferShow" title="客户移交" @ok="confirmTransfer" destroyOnClose :confirmLoading="transferLoading">
    <div>
      <a-select
        :filter-option="false"
        show-search
        placeholder="请输入员工姓名或关键字,按下回车搜索"
        @search="employerValue"
        style="width: 100%"
      >
        <template v-if="flagLoading" #notFoundContent>
          <a-spin size="small" />
        </template>
        <a-select-option
          v-for="item in employeeOptions"
          :key="item.id"
          :value="item.name"
          @click="employeeChoose(item)"
          >{{ item.name }}-{{ item.no }}-{{ item.mobile }}</a-select-option
        >
      </a-select>
    </div>
  </a-modal>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue'
import { message } from 'ant-design-vue'
import HxTable from '@/components/HxTable'
import { employeeList } from '@/api/crmManagement/setRules'
import { receive } from '@/api/crmManagement/comSea'
import { addTotal, transferCustom } from '@/api/crmManagement/tool'
import { getPageList } from '@/api/crmManagement/cooperativeCustomers'
import CustomerDetail from '../tool/customerDetail/index.vue'
import { useStore } from 'vuex'
import { focusAdd, focusCancel } from '@/api/businessCenter/businessCenter'
export default {
  components: {
    HxTable,
    CustomerDetail
  },
  setup () {
    const store = useStore()
    const state = reactive({
      totalbarNum: 0,
      search: {
        time: '',
        channel: '',
        clue: '',
        drop: '',
        coopType: null, // 成交
        coopOldType: null, // 合作
        isBlack: null, // 是否拉黑
        riskGrade: null, // 风险等级
        qualityGrade: null, // 质量等级
        vehicleGrade: null, // 台量等级
        customerType: null // 客户状态
      },
      searchForm: {
        name: '',
        saleName: '',
        mobile: '',
        followTime: '',
        channel: 0,
        tagId: null,
        followEndTime: null,
        followStartTime: null,
        coopStartTime: null,
        coopEndTime: null,
        intentionTypeId: null,
        intentionLevel:null,
        followStartNum: null,
        followEndNum:null
      },
      detailDataRecord: {},
      mirrorSearchForm: {},
      customerId: '',
      addForm: {},
      selectedRowKeys: [],
      employeeOptions: [],
      transferLoading: false,
      customTransferShow: false,
      flagLoading: false,
      searchLoading: false,
      searchReceive: true,
      searchReceiveShow: false,
      searchAddShow: false,
      followShow: false,
      timer: null,
      transferForm: {
        empAfterName: '',
        empAfterId: ''
      },
      columns: [
        {
          title: '客户名称',
          dataIndex: 'name',
          width: '7%',
          fixed: 'left',
          slots: {
            customRender: 'name'
          }
        },
        {
          title: '客户等级',
          dataIndex: 'level',
          // width: '5%',
          fixed: 'left',
          slots: {
            customRender: 'level'
          }
        },
        {
          title: '客户状态',
          dataIndex: 'customerStatus',
          slots: {
            customRender: 'customerStatus'
          }
        },
        {
          title: '意向等级',
          dataIndex: 'intentionLevel.label',
          width: '4%',
          slots: {
            customRender: 'intentionLevel'
          }
        },
        {
          title: '业务员',
          dataIndex: 'saleName',
          width: '4%'
        },
        {
          title: '客户来源',
          dataIndex: 'customerSourceType.label',
          width: '4%',
        },
        {
          title: '客户类型',
          dataIndex: 'customerType.label',
          width: '4%'
        },
        {
          title: '跟进信息',
          dataIndex: 'data',
          width: '10%',
          slots: {
            customRender: 'data'
          }
        },
        {
          title: '下次联系时间',
          dataIndex: 'nextFollowTime'
          // width: '5%',
        },
        {
          title: '联系电话',
          dataIndex: 'mobile'
          // width: '5%'
        },
        {
          title: '创建日期',
          dataIndex: 'createTime'
          // width: '6%'
        },
        {
          title: '领取日期',
          dataIndex: 'enterPrivateTime'
          // width: '6%'
        },
        {
          title: '最新分配时间',
          dataIndex: 'updateTime'
          // width: '6%'
        },
        {
          title: '最后下单日期',
          dataIndex: 'orderLastTime'
          // width: '6%'
        },
        {
          title: '结算方式',
          dataIndex: 'settlementMode.label',
          width: '4%'
        },
     
        // {
        //   title: '企业识别号',
        //   dataIndex: 'certificateNumber',
        //   width: '10%'
        // },
        {
          title: '合作日期',
          dataIndex: 'enterCoopTime'
          // width: '7%'
        },
        {
          title: '合同日期',
          dataIndex: 'contractTime',
          // width: '8%',
          slots: {
            customRender: 'contractTime'
          }
        },
        {
          title: "关注",
          dataIndex: "isFocus",
          slots: {
            customRender: 'isFocus'
          },
          align: 'center',
          fixed: 'right',
          // width: '8%'
        },
        // {
        //   title: '微信号',
        //   dataIndex: 'endAddress'
        // },
        // {
        //   title: '客户标签',
        //   dataIndex: 'tagName'
        //   // width: '10%'
        // }
      ],
      listData: [],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      }
    })
    const tagChange = (e, v) => {
      state.searchForm.tagId = e
    }
    const getCurrentStyle = (current) => {
      const style = {}

      if (current.date() === 1) {
        style.border = '1px solid #1890ff'
        style.borderRadius = '50%'
      }
      return style
    }
    const customerChange = (e, v) => {
      state.searchForm.customerType = v
    }
    const settlementChange = (e, v) => {
      state.searchForm.settlementMode = v
    }
    const focusCustomer = (item, type) => {
      if (type === 1) {
        focusAdd({
          focusType: 1,
          objectId: item.customerId
        }).then(res => {
          if (res.code !== 10000) return
          message.success('关注成功')
          loadData()
        })
      } else {
        focusCancel({
          focusType: 1,
          objectId: item.customerId
        }).then(res => {
          if (res.code !== 10000) return
          message.success('取消关注成功')
          loadData()
        })
      }
    }
    // 重置
    const reset = () => {
      state.searchForm = JSON.parse(state.mirrorSearchForm)
      state.search = {}
      loadData()
    }
    // 查询
    const onSearch = () => {
      state.searchLoading = true
      state.pagination.current = 1
      loadData()
    }
    // 合作时间查询
    const timeStartChange = (e, v) => {
      state.searchForm.coopStartTime = v
    }
    // 合作时间查询
    const timeEndChange = (e, v) => {
      state.searchForm.coopEndTime = v
    }
    // 高级搜索方法
    const onSearchSenior = () => {
      state.searchLoading = true
      state.pagination.current = 1
      loadData()
    }
    const followStartTimeChange = (e, v) => {
      state.searchForm.followStartTime = v
    }
    const followEndTimeChange = (e, v) => {
      state.searchForm.followEndTime = v
    }
    // 每日领取客户数额
    const addTotalMethod = () => {
      addTotal().then((res) => {
        if (res.code === 10000) {
          state.totalbarNum = res.data.highSeasTotalNum - res.data.highSeasNum
        }
      })
    }
    const loadData = () => {
      state.searchLoading = true
      getPageList({
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.pageSize
      })
        .then((res) => {
          if (res.code === 10000) {
            state.listData = res.data.records
            state.listData.forEach((item) => {
              if (item.name === null || item.name === '' || item.name === undefined) { item.name = '无' }
            })
            state.pagination.current = res.data.current
            state.pagination.pageSize = res.data.size
            state.pagination.total = res.data.total
            // console.log(state.data)
          }
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          state.searchLoading = false
        })
      addTotalMethod()
    }
    // 表格数据页码改变事件
    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    const onSelectChange = (selectedRowKeys) => {
      state.selectedRowKeys = selectedRowKeys
      if (selectedRowKeys.length > 0) {
        state.searchReceive = false
      } else {
        state.searchReceive = true
      }
    }
    // 渠道来源子类赋值
    const onChannel = (e) => {
      state.searchForm.children = e
    }
    // 领取客户
    const onSearchReceive = (e) => {
      if (
        state.selectedRowKeys[0] !== '' ||
        state.selectedRowKeys[0] !== null ||
        state.selectedRowKeys[0] !== undefined
      ) {
        receive(state.selectedRowKeys[0]).then((res) => {
          if (res.code === 10000) {
            message.success(res.msg)
            state.searchReceiveShow = false
            loadData()
          }
        })
      }
    }
    // 新增
    const onAddForm = () => {}
    // 查看跟记录
    const onSee = (e) => {
      state.detailDataRecord = e
      state.customerId = e.customerId
      state.followShow = true
    }

    const setChannel = (value, selectedOptions) => {
      state.searchForm.channel = value[0]
      if (value.length > 1) {
        state.searchForm.channel = value[1]
      }
    }
    const onClose = () => {
      loadData()
      state.followShow = false
    }
    // 员工搜索
    const employerValue = (e) => {
      if (e === '' || e === null) clearTimeout(state.timer), (state.fetching = false)
      state.flagLoading = true
      if (state.timer !== null) {
        clearTimeout(state.timer)
      }
      state.timer = setTimeout(() => {
        employeeList({
          current: 1,
          name: e,
          size: 10
        })
          .then((res) => {
            if (res.code === 10000) {
              state.employeeOptions = res.data
              state.flagLoading = false
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }, 1000)
    }
    // 选择员工
    const employeeChoose = (item) => {
      state.transferForm.empAfterName = item.name
      state.transferForm.empAfterId = item.id
      state.employeeOptions = []
    }
    // 确认移交
    const confirmTransfer = () => {
      state.transferLoading = true
      transferCustom({
        ...state.transferForm,
        customerClass: 3,
        customerIds: state.selectedRowKeys
      })
        .then((res) => {
          if (res.code === 10000) {
            message.success(res.msg)
            loadData()
            state.customTransferShow = false
            state.transferForm.empAfterName = ''
            state.transferForm.empAfterId = ''
            state.selectedRowKeys = []
          }
        })
        .finally(() => {
          state.transferLoading = false
        })
    }
    onMounted(() => {
      loadData()
      state.mirrorSearchForm = JSON.stringify(state.searchForm)
      state.labelAll = store.state.app.labelAll
      console.log(state.labelAll)
    })
    return {
      ...toRefs(state),
      getCurrentStyle,
      timeStartChange,
      timeEndChange,
      onSearch,
      reset,
      tagChange,
      confirmTransfer,
      employerValue,
      employeeChoose,
      followStartTimeChange,
      followEndTimeChange,
      customerChange,
      settlementChange,
      loadData,
      handleTableChange,
      addTotalMethod,
      onSelectChange,
      onSearchSenior,
      onChannel,
      onSearchReceive,
      onAddForm,
      onSee,
      setChannel,
      onClose,
      focusCustomer
    }
  }
}
</script>
<style lang="less" scoped>
@import "./index.less";
@import "../tool/index.less";
:deep(.ant-tag){
  margin-top:5px;
}
:deep(.ant-input-number-input){
  height: 34px !important;
}
</style>
